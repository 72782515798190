<template>
	<div>
		<a-tabs type="card" class="ywh-card" @change="handleTabChange">
			<a-tab-pane key="1" tab="业委会">
				<div class="ywh-card-content">
					<a-button
						type="primary"
						class="ywh-btn mb32 mr16"
						@click="openEmployeeModal"
						>导入</a-button
					>
					<a-button
						type="primary"
						class="ywh-btn mb32"
						@click="openUpsertModal"
						>新增人员</a-button
					>
					<ztable2
						:detailColumns="UOC_detail_columns"
						:columns="UOC_table_columns"
						:dataSource="UOC_data_source"
					>
						<span slot="action" slot-scope="text, record">
							<div class="editable-row-operations">
								<!-- <a
									href="javascript:void(0)"
									@click="handleEditByUOC(record)"
									>编辑</a
								> -->
								<a
									href="javascript:void(0)"
									@click="handleDeleteByUOC(record._id)"
									v-if="record.position !== 'DIRECTOR'"
									>删除</a
								>
							</div>
						</span>
					</ztable2>
				</div>
			</a-tab-pane>
			<a-tab-pane key="2" tab="业主小组">
				<div class="ywh-card-content">
					<OwnersGroup></OwnersGroup>
				</div>
			</a-tab-pane>
			<a-tab-pane key="3" tab="业主大会备案信息">
				<div class="ywh-card-content">
					<record-info></record-info>
				</div>
			</a-tab-pane>
			<!-- <a-tab-pane key="3" tab="银行账户信息">
        <div class="ywh-card-content">
          <div class="ywh-desc mb24">
            <a-row>
              <a-col :span="6">
                <div class="ywh-desc-header">
                  <div class="title">维修资金详情</div>
                </div>
                <div class="ywh-desc-content">
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                  <div class="mt40 status">
                    状态：<a href="javascript:void(0)">审核中</a>
                  </div>
                </div>
              </a-col>
              <a-col :span="6">
                <div class="ywh-desc-header"></div>
                <div class="ywh-desc-content">
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                </div>
              </a-col>
              <a-col :span="6">
                <div class="ywh-desc-header">
                  <div class="title">
                    维修资金账户预览
                  </div>
                </div>
                <div class="ywh-desc-content">
                  <img
                    style="
												width: 100%;
												height: 214px;
												background: #c4c5cc;
											"
                  />
                </div>
              </a-col>
              <a-col :span="6">
                <div class="tl-right">
                  <a-button type="primary" class="ywh-btn">修改信息</a-button>
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="ywh-desc mb24">
            <a-row>
              <a-col :span="6">
                <div class="ywh-desc-header">
                  <div class="title">维修资金详情</div>
                </div>
                <div class="ywh-desc-content">
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                  <div class="mt40 status">
                    状态：<a href="javascript:void(0)">审核中</a>
                  </div>
                </div>
              </a-col>
              <a-col :span="6">
                <div class="ywh-desc-header"></div>
                <div class="ywh-desc-content">
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                  <div class="desc-item">
                    <span class="label">业主大会名称：</span>
                    <span>九龙城龙城景园</span>
                  </div>
                </div>
              </a-col>
              <a-col :span="6">
                <div class="ywh-desc-header">
                  <div class="title">
                    维修资金账户预览
                  </div>
                </div>
                <div class="ywh-desc-content">
                  <img
                    style="
												width: 100%;
												height: 214px;
												background: #c4c5cc;
											"
                  />
                </div>
              </a-col>
              <a-col :span="6">
                <div class="tl-right">
                  <a-button type="primary" class="ywh-btn">修改信息</a-button>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-tab-pane> -->
		</a-tabs>

		<a-modal
			class="ywh-modal"
			:title="employeeModalTitle"
			:visible="employeeModalVisible"
			:confirm-loading="employeeModalLoading"
			@ok="employeeModalOk"
			@cancel="employeeModalCancel"
			ok-text="确认"
			cancel-text="取消"
		>
			<h6>下载业委会人员信息模版</h6>
			<p class="space-between">
				<span>请严格按照给定的模版格式填写，否则会导致解析失败。</span>
				<a href="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/template/%E4%B8%9A%E5%A7%94%E4%BC%9A%E4%BA%BA%E5%91%98%E4%BF%A1%E6%81%AF%E6%A8%A1%E7%89%88.xlsx" target="_blank">下载模版</a>
			</p>
			<p class="mb24">
				*注意：上传后，将覆盖原始数据，每次只保留最新上传的数据。
				<br>
				采用MD5加密算法，保证您的数据安全，防止泄漏。
			</p>
			<zform
				:formConfig="UOC_form_config"
				:formData="{}"
				ref="UOC-model-form"
			></zform>
		</a-modal>

		<a-modal
			class="ywh-modal"
			title="业委会人员信息"
			:visible="upsertModalVisible"
			:confirm-loading="upsertModalLoading"
			@ok="upsertModalOk"
			@cancel="upsertModalCancel"
			ok-text="确认"
			cancel-text="取消"
		>
			<p class="mb24">
				采用MD5加密算法，保证您的数据安全，防止泄漏。
			</p>
			<zform
				:formConfig="UOC_upsert_form_config"
				:formData="{}"
				ref="upsert-model-form"
			></zform>
		</a-modal>
	</div>
</template>

<script>
import DOC_mixin from './mixin.js'
import {
	createUOCUser,
	updateUOCUser,
	getUOCUsers,
	getUOCPositions,
	deleteUOCUser,
	createUOCUserSingle,
} from '@/api/community/UOC'
import md5 from 'js-md5'
import recordInfo from './recordInfo'
import OwnersGroup from './ownersGroup'

export default {
	name: '',
	mixins: [DOC_mixin],
	data() {
		return {
			UOC_data_source: [
				{
					id: '120',
					name: 'GavinGavin ',
				},
			],
			// 新增人员弹框
			employeeModalTitle: '业委会人员信息',
			employeeModalVisible: false,
			employeeModalLoading: false,
			upsertModalVisible: false,
			upsertModalLoading: false,
			positions: [],
			editId: null,
			activeKey: 1,
		}
	},
	methods: {
		getList() {
			getUOCUsers().then((res) => {
				console.log(res)
				this.UOC_data_source = res.list
			})
		},
		onChange(pagination, filters, sorter) {
			console.log('params', pagination, filters, sorter)
		},
		employeeModalOk() {
			this.$refs['UOC-model-form']
				.zformValidateFieldsAndScroll()
				.then(async (res) => {
					console.log('res', res);
					await createUOCUser({ ...res })
					this.$message.success('添加成功')
					this.employeeModalVisible = false
					this.getList()
					return
					console.log(res)
					//res.changeLogFile = res.uploadFile[0].response.url;
					res.pwd = md5(res.pwd)
					//delete res.uploadFile;
					delete res.compare_pwd
					this.employeeModalLoading = true

					try {
						if (this.editId) {
							await updateUOCUser(this.editId, res)
						} else {
							await createUOCUser({ ...res })
						}
						this.$message.success('添加成功')
						this.employeeModalVisible = false
						this.getList()
					} catch (error) {
						console.log(error)
					}
					this.employeeModalLoading = false
				}).catch(err => {
					console.log('err', err);
					this.$message.error('添加失败')
				})
		},
		employeeModalCancel() {
			this.employeeModalVisible = false
		},
		upsertModalOk() {
			this.$refs['upsert-model-form']
				.zformValidateFieldsAndScroll()
				.then(async (res) => {
					console.log(res)
					res.pwd = md5(res.compare_pwd)
					delete res.compare_pwd
					this.upsertModalLoading = true
					try {
						if (this.editId) {
							await updateUOCUser(this.editId, res)
						} else {
							await createUOCUserSingle({ ...res })
						}
						this.$message.success('添加成功')
						this.upsertModalVisible = false
						this.getList()
					} catch (error) {
						console.log(error)
					}
					this.upsertModalLoading = false
				}).catch(err => {
					console.log('err', err);
					this.$message.error('添加失败')
				})
		},
		upsertModalCancel() {
			this.upsertModalVisible = false
		},
		openEmployeeModal() {
			this.editId = null
			this.employeeModalVisible = true
			setTimeout(() => {
				this.$refs['UOC-model-form'].resetFields()
			})
		},
		openUpsertModal() {
			this.editId = null
			this.upsertModalVisible = true
			setTimeout(() => {
				this.$refs['upsert-model-form'].resetFields()
			})
		},
		handleEditByUOC(record) {
			this.editId = record._id
			this.upsertModalVisible = true
			setTimeout(() => {
				this.$refs['upsert-model-form'].updateForm({ ...record }, true)
			})
		},
		handleDeleteByUOC(_id) {
			this.$confirm({
				title: '确定删除该项目?',
				okText: '确定',
				okType: 'danger',
				cancelText: '取消',
				onOk() {
					deleteUOCUser(_id).then((res) => {
						this.$message.success('删除成功')
						this.getList()
					})
				},
				onCancel() {
					console.log('Cancel')
				},
			})
		},
		handleTabChange(activeKey) {
			this.activeKey = activeKey
		},
	},
	created() {
		this.getList()
		// 获取职位信息
		getUOCPositions().then((res) => {
			this.positions = res.list.map((item) => {
				return {
					text: item.name,
					value: item.value,
				}
			})
			this.UOC_form_config.columns.map((item) => {
				if (item.props === 'position') {
					item.options = this.positions
				}
			})
			this.UOC_detail_columns.map((item) => {
				if (item.props === 'position') {
					item.mapping = this.positions
				}
			})
		})
	},
	components: {
		recordInfo,
		OwnersGroup,
	},
}
</script>
<style lang="scss">
.mb32 {
	margin-bottom: 32px;
}
.mt40 {
	margin-top: 40px;
}
</style>
